
import { Component, Vue } from 'vue-property-decorator';
import { loginServe } from '@/api/installServer';
import { Gopage } from '@/mixins/gopage';
import BottomModule from '@/components/bottomModule/bottomModule.vue';

@Component({
  components: { BottomModule },
  mixins: [Gopage],
})
export default class Login extends Vue {
  private activeName: any = 'first';
  private autoplay: any = true;
  private scyfPic: any = require('../../base/lunbo/scyf/scyfpc.png');
  private aqjyPic: any = require('../../base/lunbo/aqjy/aqjypc.png');
  private zhjgPic: any = require('../../base/lunbo/aqjy/zhjg.png');
  private fxjcPic: any = require('../../base/lunbo/aqjy/fxjcpc.png');
  private picList: any = [
    {
      picSrc: require('../../base/lunbo/index-bg.png'),
    },
    {
      picSrc: require('../../base/lunbo/scyf.png'),
    },
    {
      picSrc: require('../../base/lunbo/aqjyLow.png'),
    },
    {
      picSrc: require('../../base/lunbo/xjxcLow.png'),
    },
    {
      picSrc: require('../../base/lunbo/zhjgLow.png'),
    },
    {
      picSrc: require('../../base/lunbo/sbglLow.png'),
    },
    {
      picSrc: require('../../base/lunbo/fxjcLow.png'),
    },
  ];
  private dropdownIcon: any = 'el-icon-s-unfold';

  /**
   * @desc Vue 创建数据生命周期，获取验证码
   * @param1 暂无
   * @returns 暂无
   */
  created() {}

  mounted() {
    this.slideBanner();
  }

  handleToProduct(data: any) {
    this.$router.push({ name: 'ProductCenter', params: { type: data } });
  }

  handleToProductInner(data: any) {
    this.$router.push({ name: 'ProductCenter', params: { sysType: data, router: 'login' } });
  }

  // beforeDestroy() {
  //   window.scrollTo({ top: 0 });
  // }

  visibleChange(e: any) {
    this.dropdownIcon = e ? 'el-icon-close' : 'el-icon-s-unfold';
  }

  goToBusinessRegistration() {
    this.$router.push('/registration');
  }

  //是否自动播放
  stopAuto() {
    this.autoplay = false;
  }
  startAuto() {
    this.autoplay = true;
  }

  // 轮播手滑切换
  slideBanner(data?: any) {
    console.log(data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that: any = this;
    //选中item的盒子
    const box: any = document.querySelector('.el-carousel__container');
    //手指起点X坐标
    let startPoint: any = 0;
    //手指滑动重点X坐标
    let stopPoint: any = 0;

    //重置坐标
    const resetPoint = function() {
      startPoint = 0;
      stopPoint = 0;
    };

    //手指按下
    box.addEventListener('touchstart', function(e: any) {
      //手指按下的时候停止自动轮播
      that.stopAuto();
      //手指点击位置的X坐标
      startPoint = e.changedTouches[0].pageX;
    });
    //手指滑动
    box.addEventListener('touchmove', function(e: any) {
      //手指滑动后终点位置X的坐标
      stopPoint = e.changedTouches[0].pageX;
    });
    //当手指抬起的时候，判断图片滚动离左右的距离
    box.addEventListener('touchend', function() {
      if (stopPoint == 0 || startPoint - stopPoint == 0) {
        resetPoint();
        that.startAuto();
        return;
      }
      if (startPoint - stopPoint > 0) {
        resetPoint();
        (that.$refs['carousel'] as any).next();
        that.startAuto();
        return;
      }
      if (startPoint - stopPoint < 0) {
        resetPoint();
        (that.$refs['carousel'] as any).prev();
        that.startAuto();
        return;
      }
    });
  }
}
